@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-datepicker/dist/react-datepicker.css";

@layer base {
@font-face {
  font-family: 'THSarabunNew';
  src: url('./fonts/THSarabunNew.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: 'THSarabunNew Bold';
  src: url('./fonts/THSarabunNew_Bold.ttf') format("truetype");
  font-style: Bold;
}

@font-face {
  font-family: 'THSarabunNew BoldItalic';
  src: url('./fonts/THSarabunNew_BoldItalic.ttf') format("truetype");
  font-style: Bold italic;
}

@font-face {
  font-family: 'THSarabunNew Italic';
  src: url('./fonts/THSarabunNew_Italic.ttf') format("truetype");
  font-style: italic;
}

@font-face {
  font-family: 'ModnyRegular';
  src: url('./fonts/Tour_De_Force_ModnyRegular.otf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: 'ModnyInline';
  src: url('./fonts/Tour_De_Force_ModnyInline.otf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: 'ModnyLight';
  src: url('./fonts/Tour_De_Force_ModnyLight.otf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: 'ModnySemiBold';
  src: url('./fonts/Tour_De_Force_ModnySemiBold.otf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: 'ModnyThin';
  src: url('./fonts/Tour_De_Force_ModnyThin.otf') format("truetype");
  font-style: normal;
}
}
body {
  scroll-behavior: smooth;
}
