.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.txt-white {
  color: white;
}


.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.child-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* custom slick ui */

.slick-prev {
  left: 19.5% !important;
  z-index: 1;
  width: 66px;
  height: 66px;
  transform: translate(0, -100%);
}

.slick-next {
  right: 19.5% !important;
  z-index: 1;
  width: 66px;
  height: 66px;
  transform: translate(0, -100%);
}
@media (max-width: 768px) {
  .slick-prev {
    left: 20.5% !important;
    width: 40px;
    height: 40px;
  }
  
  .slick-next {
    right: 20.5% !important;
    width: 40px;
    height: 40px;
  }
}

.slick-active {
  opacity: 1 !important;
}

.slick-slide {
  opacity: 0.5;
}

.slick-prev:before, .slick-next:before {
  color: transparent;
}

.slick-initialized .slick-slide {
  padding: 25px;
}

button:disabled, button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

button.disable {
  opacity: 0.6;
}

.single-slide .slick-prev {
  left: -1% !important;
  z-index: 1;
  width: 66px;
  height: 66px;
  transform: translate(0, -110%);
}

.single-slide .slick-next {
  right: -1% !important;
  z-index: 1;
  width: 66px;
  height: 66px;
  transform: translate(0, -110%);
}

@media (max-width: 768px) {
  .single-slide .slick-prev {
    left: 0% !important;
    width: 40px;
    height: 40px;
  }

  .single-slide .slick-next {
    right: 0% !important;
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 432px) {
  .slick-prev, .slick-next {
    transform: translate(0, -100%);
  }
}

.slick-dots {
  bottom: 50px;
}

.slick-dots li {
  width: 10px;
  height: 10px;
}

.slick-dots li button:before {
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  color: #284734;
  opacity: 1;
}

.slick-dots li button:before {
  color: #FFFFFF;
  opacity: 1;
}

.slick-track {
  justify-content: center;
  display: flex;
  align-items: center;
}

/* custom ui datepicker */

.react-datepicker__input-container input {
  background-color: #284734;
  border: 1px solid #FFFFFF;
  padding: 8px;
}

.react-datepicker__input-container input::placeholder {
  color: #FFFFFF;
}

.css-18gu508-Content {
  font-family: 'THSarabunNew';
  font-size: 20px !important;
}

.list-desc ul {
  list-style: disc;
}